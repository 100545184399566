import React from 'react'
import { navigate } from 'gatsby'

import { useMagic } from 'stores'
import Button from '../components/Button'
import Container from '../components/Container'
import Section from '../components/Section'
import SEO from '../components/seo'

const browser = typeof window !== 'undefined' && window

const NotFoundPage = () => {
  const { isAuthenticated } = useMagic()
  return (
    browser && (
      <>
        <SEO title="404: Not found" />
        <Section
          fullPage
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Container
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%'
            }}
          >
            <h1>404 Not Found</h1>
            <p>
              You just hit a route that doesn&#39;t exist... weird! Just hit the
              button below to go back{' '}
              {isAuthenticated ? 'to your dashboard' : 'home'}
            </p>
            {}
            <Button
              onClick={
                isAuthenticated
                  ? () => navigate('/app/dashboard')
                  : () => navigate('/')
              }
            >
              {isAuthenticated ? 'Dashboard' : 'Home'}
            </Button>
          </Container>
        </Section>
      </>
    )
  )
}

export default NotFoundPage
