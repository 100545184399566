import React from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import NotFoundPage from '../404'

import { convertTitle } from 'utils/helpers'
import { PrivateRoute, Dashboard, Reader } from 'components'

const App = (props) => (
	<Router>
		<PrivateRoute path="/app/dashboard" component={Dashboard} data={props} />
		{props.data.allContentfulBook.nodes.map((node) => {
			if (node.chapters) {
				return node.chapters.map((chapter) => {
					if (chapter.sections) {
						return chapter.sections.map((section) => (
							<Reader
								key={section.id}
								path={`/app/book/${convertTitle(node.title)}/chapter${chapter.order
									}/${section.order}`}
								book={node}
								chapter={chapter}
								section={section}
							/>
						))
					}
				})
			}
		})}
		<PrivateRoute path="*" component={NotFoundPage} />
	</Router>
)

export const query = graphql`
	query {
		allContentfulBook {
			nodes {
				id
				contentful_id
				availability
				availabilityDate
				title
				chapters {
					id
					title
					order
					sections {
						isAvailableAsSample
						id
						title
						order
						content {
							content
						}
					}
				}
				cover {
					fluid {
						...GatsbyContentfulFluid_withWebp
					}
				}
			}
		}
	}
`

export default App
